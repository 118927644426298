import React from 'react';
import { contactMethods } from "../Data/contactMethods.js";

export default function ContactCards() {
    return (
        <div style={{display:'flex', flexWrap:"wrap" }}>
            {contactMethods.map(contact =>
                <div style={{width:'325px',height:'100px',borderRadius:'5px',margin:'10px auto',backgroundColor:'#1A1D35'}} key={contact.id}>
                    <img
                        src={contact.src}
                        alt={contact.alt}
                        style={{width:'38px', float:'left', margin:'30px 20px'}}
                    />
                    <h2 style={{textAlign:'left',margin:'15px'}}>{contact.title}</h2>
                    <p style={{textAlign:'left'}}>{contact.desc}</p>
                </div>
            )}
        </div>
    );
}
