import Phone from '../Assets/Call.png';
import Location from '../Assets/Location on.png';
import Email from '../Assets/Mail outline.png';

export const contactMethods = [
    {
        id : 0,
        src : Location,
        title : "Location",
        alt : "Location-Map Logo",
        desc : "Bebington, Wirral, UK"
    },
    {
        id : 1,
        src : Email,
        title : "Email",
        alt : "Email-Contact Logo",
        desc : "mail@jetclouds.co.uk"
    },
    {
        id : 2,
        src : Phone,
        title : "Phone",
        alt : "Phone-Contact Logo",
        desc : "07776 947 463"
    }];