import React from "react";
import { useEffect, useState } from "react";
import F22Raptor from '../Assets/F22Raptor.png'

const  BackToTopJet = () => {
    const [backToTopJet, setBackToTopJet] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setBackToTopJet(true)
            } else {
                setBackToTopJet(false)
            }
        });
    }, [])

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div>
            {backToTopJet && (
                <img onClick={scrollTop}  src={F22Raptor} alt="jet icon"
                    style={{
                        position: 'fixed',
                        bottom: '5%',
                        right: '4%',
                        width: '42px',
                        cursor: 'pointer'
                    }}
               />
            )}
        </div>
    );
};
export default BackToTopJet;
