import React from 'react';
import './Navbar.css';
import { HashLink as Link } from 'react-router-hash-link';
import {NavLink } from "react-router-dom";
import { useState } from "react";
import jetCloudsLogo from '../Assets/jetCloudsLogo.png'

import { BsList, BsXCircleFill, BsFillHouseFill,BsFillTelephoneFill,BsGithub, BsLinkedin, BsTools,BsPersonLinesFill} from 'react-icons/bs';

export default function Navbar() {
    
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    return (
        <div>
            <nav className='navigation'>
                <div className='nav-title'>
                    <img className="logo" src={jetCloudsLogo} alt='jetclouds logo png'/>
                    <h2 style={{margin:'17px 45px 17px auto'}}>jetClouds</h2>
                </div>

                <div className={isNavExpanded ? "nav-list expanded" : "nav-list"}>

                    <BsList className='hamburger' onClick={() => {
                        setIsNavExpanded(!isNavExpanded)
                    }}></BsList>

                    <ul>
                        <li><NavLink className='home-link' style={{color:'#9D00FF', borderBottom:'3px solid #9D00FF'}} to="/" onClick={() => setIsNavExpanded(false)}><BsFillHouseFill className='nav-icon' style={{color:'#9D00FF'}}/>Home</NavLink></li>                        
                        <li><Link smooth to="#services"  onClick={() => setIsNavExpanded(false)}><BsTools className='nav-icon'/>Services</Link></li>
                        <li><Link smooth to="#about"  onClick={() => setIsNavExpanded(false)}><BsPersonLinesFill className='nav-icon'/>About</Link></li>
                        <li><Link smooth to="#contact-methods" onClick={() => setIsNavExpanded(false)}><BsFillTelephoneFill className='nav-icon'/>Contact</Link></li>
                    </ul>
                   
                    <BsXCircleFill className='close-menu' onClick={() => {
                        setIsNavExpanded(false)
                    }}></BsXCircleFill>
                </div>

            </nav>
        </div>

    );
}