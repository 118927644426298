import React from 'react';
import jetCloudsLogo from '../Assets/jetCloudsLogo.png'
import profilePic from '../Assets/selfie-nobg-hd 1.png'
import webDesignGraphic from '../Assets/WebDesignGraphic.png'
import webDevGraphic from '../Assets/WebDevelGraphic.png'
import designGraphic from '../Assets/GraphicDesign.png'
import uniChester from '../Assets/UniversityOfChesterLogo.png'
import northlandTech from '../Assets/NorthLandTechLogo.png'
import ContactForm from '../Components/ContactForm.js';
import ContactCards from '../Components/ContactCards.js';
import Footer from '../Components/Footer.js';
import BackToTopJet from './BackToTopJet';

export default function Home() {
    return (
        <div>
            <BackToTopJet />
            
            <div className='landing-message' >
                <img className="logo" src={jetCloudsLogo} style={{ margin: '50px auto 0px auto', width: '250px' }} />
                <h1 style={{ color: 'whitesmoke', margin: '0px auto 20px auto' }}>Welcome to jetClouds</h1>
                <h2 className='message-content' >Web Design and Web Development</h2>
                <h2 className='message-content' >Based in the Wirral, UK</h2>
            </div>
            <div className='services' id='services'>
                <h3>Services</h3>
                <h1 style={{ color: 'whitesmoke', margin: '0px auto 20px auto' }}>My Services</h1>
                <div className='card'>
                    <div className='card-image' style={{ boxShadow: '10px -15px 50px rgba(0, 23, 35)' }}>
                        <img src={webDesignGraphic} alt='' style={{ maxWidth: '300px', margin: '70px auto', maxHeight: '300px', filter: `drop-shadow(10px 10px 50px rgba(2, 222, 252,.5))` }}></img>
                    </div>
                    <div className='card-content'>
                        <h5 className='card-title'>Website Design</h5>
                        <p>Fully Customizable User Interfaces will be responsive due to UI/UX guidelines and fundamentals.
                            This ensures your visitors can easily interact with your site across various sized devices.</p>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-image' style={{ boxShadow: '10px -15px 50px rgba(0, 23, 35)' }}>
                        <img src={webDevGraphic} alt='' style={{ maxWidth: '300px', margin: '12px auto', maxHeight: '300px', filter: `drop-shadow(10px 10px 50px rgba(0, 87, 52))` }}></img>
                    </div>
                    <div className='card-content'>
                        <h5 className='card-title'>Website Development</h5>
                        <p>A Design will be developed using commonly known programming languages such as React, Html, and CSS while being hosted on protected servers.
                            Utilizing these technologies enables your organization to be scalable and versatile for the ever changing market. </p>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-image' style={{ boxShadow: '10px -15px 50px rgba(0, 23, 35)' }}>
                        <img src={designGraphic} alt='' style={{ maxWidth: '300px', margin: '40px auto', maxHeight: '300px', filter: `drop-shadow(10px 10px 50px rgba(2, 222, 252,.25))` }}></img>
                    </div>
                    <div className='card-content'>
                        <h5 className='card-title'>Graphic Design</h5>
                        <p>I’ll help you personalize Logos, Icons and Background Tiles unique to your brand.
                            Let me transform your amazing ideas to a digital file format usable in countless applications.</p>
                    </div>
                </div>
            </div>
            <div className='about' id='about'>
                <h3 style={{width:'100%', textAlign:'center'}}>About</h3>
                <div className='profile-pic'>
                    <img src={profilePic} alt='' style={{ width: '300px' }}></img>
                </div>
                <div className='profile-content'>
                    <h5 style={{marginBottom:'12px'}}>Hey there,</h5>
                    <h1 style={{ color:'#9D00FF', margin: '0px auto 20px auto' }}>I'm Peter.</h1>
                    <p>A UI/UX Designer and Front-End Developer</p>
                    <p style={{ marginBottom: '40px' }}> Let me  elevate your business in the cloud</p>
                    <h1 style={{ color: 'whitesmoke', margin: '0px auto 0px auto', backgroundColor: '#1A1D35', padding: '15px' }}>Favorite Tech</h1>
                    <div className='tech-stack'>
                        <div className='stack-card'>
                            <h5>Design Tools</h5>
                            <p>Figma, Krita</p>
                        </div>
                        <div className='stack-card'>
                            <h5>Programming Languages</h5>
                            <p >React, C#, C++, Python</p>
                        </div>
                        <div className='stack-card'>
                            <h5>IDE</h5>
                            <p>VsCode, Visual Studios</p>
                        </div>
                    </div>

                    <h1 style={{ color: 'whitesmoke', margin: '20px auto 30px auto' }}>Education</h1>

                    <div className='edu-stack'>
                        <div className='edu-card'>
                            <h5 style={{ textAlign: 'center', margin: '20px auto' }}>BSc (Hons) Computer Science</h5>
                            <p>University of Chester</p>
                            <p style={{ fontStyle: 'italic' }}>2020-2023</p>

                            <img src={uniChester} alt='' style={{ width: '50px', margin: '10px auto' }}></img>
                        </div>
                        <div className='edu-card'>
                            <h5 style={{ textAlign: 'center', margin: '20px auto' }} >Associate of Applied Science (AAS)
                                Computer Service and Networking</h5>
                            <p>Northland Community & Technical College</p>
                            <p style={{ fontStyle: 'italic' }}>2012-2014</p>
                            <img src={northlandTech} alt='' style={{ width: '50px', margin: '10px auto' }}></img>
                        </div>
                    </div>
                </div>

            </div>
            <div className='get-in-touch' id="contact-methods">
                <h3>Get In Touch</h3>
                <h1 style={{ color: 'whitesmoke', margin: '0px auto 20px auto' }}>Contact Methods</h1>
                <ContactCards />
                <ContactForm />
            </div>
            <Footer />
        </div>
    );
}