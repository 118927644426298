import React from 'react';
import './App.css';
import Home from './Components/Home.js';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';

export default function App(){
  return (
    <BrowserRouter>
      <div className='App'>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

