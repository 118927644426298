import React from 'react';
import jetCloudsLogo from '../Assets/jetCloudsLogo.png'
import { BsGithub, BsLinkedin } from 'react-icons/bs';

export default function Footer() {
    const iconStyle = {
        color: 'whitesmoke',
        margin: '0px 10px',
        fontSize: '28px'
    };
    return (
        <div className="footer" style={{ textAlign: 'center', height: '250px', width: '100%', borderTop: '2px solid #3d3d3d' }}>
            <h3 style={{ margin: '20px auto' }}>Powered By</h3>
            <img src={jetCloudsLogo} alt='' style={{ width: '100px' }}></img>
            <h2 style={{ margin: '-2px auto' }}>jetClouds</h2>
            <div className="social-media" style={{ margin: '15px auto', textAlign: 'center' }}>
                <a href="https://www.linkedin.com/in/peter-manz-a76371280/"><BsLinkedin style={iconStyle} /></a>
                <a href="https://github.com/Peter-Manz"><BsGithub style={iconStyle} /></a>
            </div>
            <p>@2023 jetclouds.co.uk | All Rights Reserved</p>
        </div>
    )
}